import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'
import Img from 'gatsby-image'
import { graphql, Link } from 'gatsby'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import things_logo from '../../assets/images/projects/service/tech/things-logo.jpg'
import keycrime_logo from '../../assets/images/projects/service/keycrime/keycrime-logo.png'
import video1 from '../../assets/images/projects/service/keycrime/outcome/26.mp4'
import { useRef } from 'react'
import pic04 from '../../assets/images/projects/22.png'




const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const ServiceDesignKC = props => {

  const outcomeDiv = useRef(null);

  const scrolLWithUseRef = () => outcomeDiv.current?.scrollIntoView({ block: "start", behavior: "smooth" });

  return (
  
  <Layout>
    <Helmet>
      <title>Digital product design - Keycrime</title>
      <meta
        name="Product Design - Keycrime"
        content="Redesigning web application for criminal investigation"
      />
    </Helmet>

    <div id="main" className="alt">
      <section id="work-banner">
        <div>
          <span className="image main">
            <Img fluid={props.data.kccover.childImageSharp.fluid} alt="" />
          </span>
        </div>
      </section>

      <div className="project-wrapper">
        <div className="project-content">
          <header>
          <p className="maisontags">Research, Information Architecture, Digital UX/UI, Brand Idendity</p>
            {/* <span className="tag">INTERACTION DESIGN</span>
            <span className="tag">DIGITAL UX</span>
            <span className="tag">DIGITAL UI</span> */}
            <h1>keycrime</h1>
            <h3>
            We redesigned the information architecture, user flows & interaction 
            patterns of an operative and analytical web application of Predictive Policing.
            <br />
            <br />
            It improved the workflow efficiency &
              effectiveness of police officers allowing them to fully focus
              on their investigative work.
            </h3>
          </header>

          <section className="header-post">
              <div className="headerpost">
                

                <div className="headerpost-right">
                  <div className="client-image">
                    <img className="span-4" src={things_logo} alt="things logo" />
                  </div>
                  <br />
                  <p>
                    This project was carried out while working at the design agency, THINGS, in Milan.
                    <br />
                    <br />
                    <a href="/about-things"><u>know more about THINGS</u></a>
                  </p>
                </div>
              </div>
            </section>

            <div className="process">
              <div className="process-left">
                <h4>
                  Project context
                </h4>
                <p>KeyCrime, a digital tool, was created ten years ago by a policeman, today CEO of the company, Mario Venturi.
                  <br />
                  <br />
                  It was developed to fight crime in the metropolitan 
                  area of Milan. it analyses different kind of crimes
              such as robbery, sexual violence, theft, homicide and scam.
                    <br />
                    <br />
                    The police department of Milan (Italy) approached us to redesign the entire digital tool with the aim of making it more user-friendly.
                    <br />
                    <br />
                    <p>KeyCrime combines the investigation and analytical experience of
                   police officers with complex algorithms to provide them with accurate crime forcasting.</p>
                    <a href="https://youtu.be/zIjnNtE93MU"><u>video on how KeyCrime works</u></a> 
                    </p>
                  
                 
                
              </div>

              <div className="process-right">
              <h4>
              Industry              
              </h4>
              <span className="credits-text">
              Information Services
              </span>
              <br />
              <h4>
              My role
              </h4>
              <span className="credits-text">
              🔍 The Researcher and 📱the UX/UI Designer.
              <br />
              <br />
              I collaborated with Police Officers, subject matter experts, Front & Back-end Developers, and Visual Designer.
              </span>
              <br />
              <div className="challenge-client">
                  {/* <br /> */}
                  <h5>Client</h5>
                  <div className="client-image">
                    <img className="span-3" src={keycrime_logo} alt="bsmart logo" />
                    {/* <img className="span-3" src={nhs_logo} alt="bsmart logo" /> */}
                  </div>
                </div>
              </div>
            </div>

            


          <section className="challenge-section">
            <span className="project-step-number">01</span>
            <h2>the challenge</h2>
            <div className="challenge">
              <div className="challenge-desc">
              <p className="half-textlarge">
              Redesign the interaction, user experience and
              visual design of the existing web application
                </p>
                <p>
              Our aim was to make all the product functionalities easy to use leading to an improvement in the 
              workflow; which in turn would allow for the product to be scaled and used by
              police officers in different cities in the world.
                </p>
              </div>

            </div>
            <div className="image">
              <Img fluid={props.data.kcchallenge.childImageSharp.fluid} />
            </div>
            

            <div className="project-outcome">
                <span className="project-outcome-link" onClick={scrolLWithUseRef}>
              see project outcomes >>
                </span>
              </div>

          </section>


          <section className="process-section">
            <span className="project-step-number">02</span>
            <h2>the approach</h2>

            <div className="process-image">
              <Img fluid={props.data.kc_process.childImageSharp.fluid} />
            </div>

            <div className="changebg">
            <div className="process">
            <div className="process-left">
            <h4>Understanding the existing KeyCrime software and workflows</h4>
            </div>
            <div className="process-right">
            <p>A complex software & technology, it was important to start by
              fully understanding the mission and reason behind why KeyCrime
              came into existence.
              <br />
              <br />
              This followed a deep dive session to understand how the existing
              software worked both from the front and back-end side. 
              <br />
              <br />
              The old
              interface of the application is shown in the pictures below. </p>
            </div>
            </div>

            <p className="full-textlarge">
            Understanding the functionalities and workflow of the
              police officers allowed us to map an information architecture of the current and future-state product.
            </p>

            <Slider className="image-scroll" {...settings}>
              <Img fluid={props.data.kcprocess2.childImageSharp.fluid} />
              <Img fluid={props.data.kcprocess3.childImageSharp.fluid} />
              <Img fluid={props.data.kcprocess4.childImageSharp.fluid} />
              <Img fluid={props.data.kcprocess5.childImageSharp.fluid} />
              <Img fluid={props.data.kcprocess6.childImageSharp.fluid} />
            </Slider>
            </div>


            <div className="bluebg">
            <p className="full-textlargebluebg">
            The design of each screen took into account the hierarchy of
              information and functionalities according to the needs of the
              police officers to carry out their tasks.
            </p>
            </div>

            <div className="process">
            <div className="process-left">
            <h4>Designing of wireframes</h4>
            </div>
            </div>

            <div className="process">
            <div className="process-left">
            <p className="half-textlarge">
            The forms were designed to allow for logical and quick
              insertion of data.
            </p>
            </div>

            <div className="process-right">
              <p>
              KeyCrime works by first entering already committed crime
              information into the system, such as time, date, place & type of
              crime.
              <br />
              <br />
              This meant grouping related information in one
              card, showing all selection options if under 6, using field length
              as an affordance, using auto-complete for longer fields and
              correctly using radio buttons, dropdowns & checkboxes depending on
              the type of information that had to be inputted.
              </p>

            </div>
            </div>

            <Slider className="image-scroll" {...settings}>
            <Img fluid={props.data.kcprocess7.childImageSharp.fluid} />
            <Img fluid={props.data.kcprocess8.childImageSharp.fluid} />
            </Slider>

            <br />
            <br />


            {/* <div className="two-sidebyside">
              <div className="img left">
                <Img fluid={props.data.kcprocess7.childImageSharp.fluid} />
              </div>
              <div className="img right">
                <Img fluid={props.data.kcprocess8.childImageSharp.fluid} />
              </div>
            </div> */}

            <div className="changebg">

            <div className="process">
              <div className="process-left">
                <p>
                  Other types of information such as facial appearance, body,
                  clothes, accessories, weapons & vehicles can then be added for
                  the criminal event.
                </p>
                <p className="half-textlarge">
                The input of these type of data required a
                  kind of interaction which had a quick navigation between the
                  different body part categories.
                </p>
              </div>
              <div className="process-right">
              <p className="half-textlarge">
              Tags were used as interaction design elements to simplify the
                  experience of first inserting high level information
                </p>
                <p>
                  Then
                  showing the contingent section of the form to insert more
                  detailed information with clear communication of the next step
                  the user has to take. 
                  <br />
                  <br />
                  Different colours were used to show the
                  different states of tags such as empty, selected & filled.
                </p>
              </div>
            </div>

            <Slider className="image-scroll" {...settings}>
            <Img fluid={props.data.kcprocess9.childImageSharp.fluid} />
            <Img fluid={props.data.kcprocess10.childImageSharp.fluid} />
            <Img fluid={props.data.kcprocess11.childImageSharp.fluid} />
            </Slider>

            </div>


            {/* <div className="two-sidebyside">
              <div className="img left">
                <Img fluid={props.data.kcprocess9.childImageSharp.fluid} />
              </div>
              <div className="img right">
                <Img fluid={props.data.kcprocess10.childImageSharp.fluid} />
              </div>
            </div>

            <div className="image">
              <Img fluid={props.data.kcprocess11.childImageSharp.fluid} />
            </div> */}


            {/* <p className="full-text">
              This same design consistency was maintained for inputting data
              related to weapons and vehicles. In addition to that the input
              fields were also made keyboard-friendly to increase its
              accessibility; “tab” was used to quickly move through the
              different fields. As this was a desktop-only product, mouse
              hover-over interaction was used to show more info and right-click
              interaction showed secondary actions that could be taken.
            </p> */}


            <div className="process">
            <div className="process-left">
            <p>
            Media gallery is a key part of KeyCrime product. Police officers
              upload images and videos from the crime event into the system to
              see, analyse and identify different elements for clues/evidence.
            </p>
            </div>

            <div className="process-right">
            <p className="half-textlarge">
            To meet this
              requirement, a custom tagging interaction was designed;
            </p>
            <p>
              where they had to choose the element to identify (e.g. red cap) and then the
              “red cap” tag can be added to parts of the image and video they
              click on.
            </p>
            </div>
            </div>


            
           
            <Slider className="image-scroll" {...settings}>
              <Img fluid={props.data.kcprocess12.childImageSharp.fluid} />
              <Img fluid={props.data.kcprocess13.childImageSharp.fluid} />
            </Slider>

            <div className="changebg">
            <div className="process">
            <div className="process-left">
            <p>
            After wireframes of key screens were finalised, the visual
              language was defined.
            </p>
            <p>2 concepts for the look & feel of the
              product were made; dark and light.</p>
            </div>
            </div>

            <p className='full-textlarge'>
            The light theme was
              chosen as it made the insertion of data fields, scannability of
              information and visualisation of data easier on the eye.
            </p>

            <Slider className="image-scroll" {...settings}>
              <Img fluid={props.data.kcprocess14.childImageSharp.fluid} />
              <Img fluid={props.data.kcprocess15.childImageSharp.fluid} />
              <Img fluid={props.data.kcprocess16.childImageSharp.fluid} />
              <Img fluid={props.data.kcprocess17.childImageSharp.fluid} />
            </Slider>

            </div>


            <div className="process">
            <div className="process-left">
            <h4>Speech to text input of information & review</h4>
            <p>
            In order to predict behavior of criminals, KeyCrime collects data
              from reports as well as from interviews designed by psychologists.
            </p>
            <p>
            In order to make this process seamless and natural, voice
              recognition was used so that witness interviews could be
              automatically converted from speech to text. 
            </p>
            </div>
            <div className="process-right">
            <p className="half-textlarge">
            This functionality
              sped up the process and reduced the chance of human error that
              used to occur while listening, and typing the
              information they heard.
            </p>
            </div>
            </div>

            <Slider className="image-scroll" {...settings}>
              <Img fluid={props.data.kcprocess18.childImageSharp.fluid} />
              <Img fluid={props.data.kcprocess19.childImageSharp.fluid} />
            </Slider>
            
            
            
            <div className="process">
              <div className="process-left">
                <p>
                  We went one step beyond by designing a user experience where
                  keywords in the interview were detected and auto-filled
                  directly into the forms. 
                </p>
                <p>
                The design then gave the officers the
                  ability to accept the automatically filled information with a
                  single click.
                </p>
                <p>
                We further designed a system that suggests officers behavioural 
                questions to ask based on the conversation flow it hears between them & witness.
                </p>
              </div>
              <div className="process-right">
                  <p className="half-textlarge">
                  Overall this UX and interaction
                  design saved the officer time and mental effort allowing to
                  focus on other things related to the interview.
            </p>
            </div>
            </div>


            <div className="changebg">
            <div className="process">
            <div className="process-left">
            <h4>Designing of search results and map visualisations</h4>
            </div>
            <div className="process-right">
            <p>
            Keycrime’s strategic partnership with IBM watson for its AI
              capabilities has enabled efficient & reliable analysis of all the
              data in the database. 
            </p>
            <p>
            The software formulates links between
              different events and data sets to help link crimes and identify
              criminal series.
            </p>
            <p>
            To allow officers to do this effectively, the search results were
              made up of tabs that grouped information into categories allowing
              for large amount of information to be concisely represented and
              easily accessible.
            </p>
            </div>
            </div>

            <p className="full-textlarge">
              A custom method was designed to facilitate the comparison of the
              different search results. By changing the tabs or scrolling down
              in any of the search result; the same thing is seen on all other
              search results. This interaction made it quick to analyse and
              compare between events at a glance.
            </p>

            

            <Slider className="image-scroll" {...settings}>
              <Img fluid={props.data.kcprocess20.childImageSharp.fluid} />
              <Img fluid={props.data.kcprocess21.childImageSharp.fluid} />
              <Img fluid={props.data.kcprocess22.childImageSharp.fluid} />
            </Slider>
            
            
            </div>


            <div className="process">
            <div className="process-left">
            <p className="half-textlarge">
              Finally, we made a 90 second explanation video about KeyCrime
              which could be shown to police agencies of other cities and
              potential investors.
            </p>
            </div>
            <div className="process-right">
            <p>
              We started by first listing down the main functionalities and
              values we had to communicate in this video.
              <br />
              <br />
               This was followed by
              making rough sketches of the frames in the storyboard and writing
              voice over script. 
              <br />
              <br />
              Inspiration research regarding the visual style
              of the video and illustrations was then made.
              <br />
              <br />
               Finally, the rough
              sketches were illustrated and all the scenes were animated.
            </p>
            </div>
            </div>

            <div className="two-sidebyside">
              <div className="img left">
                <Img fluid={props.data.kcprocess23.childImageSharp.fluid} />
              </div>
              <div className="img right">
                <Img fluid={props.data.kcprocess24.childImageSharp.fluid} />
              </div>
            </div>
          </section>

          <section id="outcome-section" ref={outcomeDiv} className="outcome-section">
            <div className="outcome-text-wrapper">
              <span className="project-step-number">03</span>
              <h2>the outcome</h2>
              <p>
                We successfully improved the usability of the KeyCrime web
                application enhancing the workflow of the police officers so
                that they can fully focus on the investigation and predict when,
                where and how the criminal is likely to hit next.
              </p>
              <h3>
                The user experience of the application was completely redesigned
                to make it easy to organise the huge amount of criminal event
                data in a easy-to-consult database.
              </h3>
              <h3>
                The simplified Interface allowed for easy usage of the various
                features of the application such as quick input different types
                of information & tagging of Video/Photo & Documents and and
                optimal navigability to access different sections and details of
                the application.
              </h3>
              <h3>
                In certain sections of the application custom interactions were
                designed to meet the specific needs of the police officers to do
                their job effectively and efficiently.
              </h3>
              <p>
                Finally, we also made sure that the newly designed application
                had a short learning curve so that it can be easily adopted by
                the current police forces in Milan and in other cities globally.
              </p>
              <p>
                Visit <a href="https://keycrime.com ">keycrime.com </a> to find
                out more.
              </p>
            </div>
            <div className="video">
              <video controls>
                <source src={video1} type="video/mp4" />
              </video>
            </div>
            <div className="image">
              <Img fluid={props.data.kcoutcome27.childImageSharp.fluid} />
            </div>
          </section>
        </div>
      </div>



      <div className="project-wrapper">
          <div className="project-content">
            {/* <div className="changebg">


              <div className="process">
                <div className="process-left">
                  <h4>
                    Problems faced during the project
                    and how they were solved
                  </h4>
                </div>
                <div className="process-right">
                  <p>
                    The challenge was to identify ways in which our product
                    assists the coaches to do their job better and not to
                    replace their role.
                  </p>
                  <p>
                    After collaborating with the coaches we found out our
                    USP which was to equip them with factual data which
                    they could not know during their training sessions;
                    such as data about impact strength and area of impact
                    of each hit.
                  </p>
                </div>
              </div>


              <div className="process">
                <div className="process-right">
                  <p>
                    Another challenge was to ensure that our product did not
                    interfere or distract the current training routines and sessions.
                  </p>
                  <p>
                    The insights from our research allowed us to design various
                    micro & macro interactions that made sure our product
                    complimented the workflow of coaches and trainers.
                    For e.g. Highlight-cards gave quick data insights.
                  </p>
                </div>
              </div>

              <div className="process">
                <div className="process-right">
                  <p>
                    Coaches during training need to see live feed of
                    data of their trainers and also see data of the
                    elapsed time. The use of bluetooth (to connect
                    and exchange data/information between the gloves
                    and mobile app) meant putting considerable strain
                    on the database, resulting in slow loading time.
                  </p>
                  <p>
                    Our solution was to take snapshots (pictures) of
                    the data during training and store that data locally.
                    So  if the user was to scroll back in the timeline,
                    either during or at the end of the training session,
                    instead of calling the data from the server we simply
                    showed the snapshots thus eliminating any loading time.
                  </p>
                </div>
              </div>

              <br />
              <br />
              <div className="process">
                <div className="process-left">
                  <h4>
                    Reflection - what I learnt during the project & what I could have done better.
                  </h4>
                </div>
                <div className="process-right">
                  <p>
                    Getting people to talk about an important, but highly complex topic was difficult.
                  </p>
                  <p>
                    We approached people in different ways (short survey, contextual interview,
                    phone conversation, in-person chats) depending on their comfort. Our
                    strategy involved raising awareness around the topic, and being open
                    to listening to stories about people's personal experiences.
                  </p>
                </div>
              </div>
            </div> */}
            <br />


            <p className="full-text">
              Thank you & keep designing user-friendly digital products!<span role="img" aria-labelledby="tecneemoji">💪📈</span>
            </p>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />


            <div className="full-text">
              <div className="hp-featured__hr-line" />
              <h5>NEXT PROJECT</h5>
            </div>
            <br />


          </div>
        </div>


        <section id="two" className="tiles inner">
          {/* <div className="hp-featured__hr-line" />
        <h4>NEXT PROJECT</h4> */}
          <div className="tiles__wrapper">
            <a href="/work/service-design-postop">
              <div className="tiles__content">
                <div className="tiles__info">
                  {/* <div className="tiles__number">01</div> */}

                  {/* <h2 className="tiles__title">
                      electric car charging service.
                    </h2> */}

                  <div className="tiles__text">
                  Improving post surgery outcomes by enabling patients to 
                    manage their recovery independently with confidence through 
                    remote monitoring by clinical teams.
                  </div>
                  <br />
                  <p className="maisontags-card">Research, UX Strategy, Service Design, User Experience, Digital UX/UI, Brand Idendity</p>
                  {/* <div className="tiles__tags">
                      <ul>
                        <li>SERVICE DESIGN</li>
                        <li>USER EXPERIENCE</li>
                        <li>DIGITAL UX/UI</li>
                        <li>INTERACTION DESIGN</li>
                      </ul>
                    </div> */}
                </div>
              </div>
              <div className="tiles__image">
                <img src={pic04} alt="" />
              </div>
            </a>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default ServiceDesignKC

export const fluidKCImage = graphql`
  fragment fluidKCImage on File {
    childImageSharp {
      fluid(maxWidth: 920, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    kccover: file(
      relativePath: {
        eq: "projects/service/keycrime/cover/keycrime-cover-image.jpg"
      }
    ) {
      ...fluidKCImage
    }
    things_logo: file(
      relativePath: { eq: "projects/service/tech/things-logo.jpg" }
    ) {
      ...fluidKCImage
    }
    keycrime_logo: file(
      relativePath: { eq: "projects/service/keycrime/keycrime-logo.png" }
    ) {
      ...fluidKCImage
    }
    kcchallenge: file(
      relativePath: { eq: "projects/service/keycrime/challenge/1.jpg" }
    ) {
      ...fluidKCImage
    }
    kcprocess2: file(
      relativePath: { eq: "projects/service/keycrime/process/2.jpg" }
    ) {
      ...fluidKCImage
    }
    kcprocess3: file(
      relativePath: { eq: "projects/service/keycrime/process/3.jpg" }
    ) {
      ...fluidKCImage
    }
    kcprocess4: file(
      relativePath: { eq: "projects/service/keycrime/process/4.jpg" }
    ) {
      ...fluidKCImage
    }
    kcprocess5: file(
      relativePath: { eq: "projects/service/keycrime/process/5.jpg" }
    ) {
      ...fluidKCImage
    }
    kcprocess6: file(
      relativePath: { eq: "projects/service/keycrime/process/6.jpg" }
    ) {
      ...fluidKCImage
    }
    kcprocess7: file(
      relativePath: { eq: "projects/service/keycrime/process/7.jpg" }
    ) {
      ...fluidKCImage
    }
    kcprocess8: file(
      relativePath: { eq: "projects/service/keycrime/process/8.jpg" }
    ) {
      ...fluidKCImage
    }
    kcprocess9: file(
      relativePath: { eq: "projects/service/keycrime/process/9.jpg" }
    ) {
      ...fluidKCImage
    }
    kcprocess10: file(
      relativePath: { eq: "projects/service/keycrime/process/10.jpg" }
    ) {
      ...fluidKCImage
    }
    kcprocess11: file(
      relativePath: { eq: "projects/service/keycrime/process/11.jpg" }
    ) {
      ...fluidKCImage
    }
    kcprocess12: file(
      relativePath: { eq: "projects/service/keycrime/process/12.jpg" }
    ) {
      ...fluidKCImage
    }
    kcprocess13: file(
      relativePath: { eq: "projects/service/keycrime/process/13.jpg" }
    ) {
      ...fluidKCImage
    }
    kcprocess14: file(
      relativePath: { eq: "projects/service/keycrime/process/14.jpg" }
    ) {
      ...fluidKCImage
    }
    kcprocess15: file(
      relativePath: { eq: "projects/service/keycrime/process/15.jpg" }
    ) {
      ...fluidKCImage
    }
    kcprocess16: file(
      relativePath: { eq: "projects/service/keycrime/process/16.jpg" }
    ) {
      ...fluidKCImage
    }
    kcprocess17: file(
      relativePath: { eq: "projects/service/keycrime/process/17.jpg" }
    ) {
      ...fluidKCImage
    }
    kcprocess18: file(
      relativePath: { eq: "projects/service/keycrime/process/18.jpg" }
    ) {
      ...fluidKCImage
    }
    kcprocess19: file(
      relativePath: { eq: "projects/service/keycrime/process/19.jpg" }
    ) {
      ...fluidKCImage
    }
    kcprocess20: file(
      relativePath: { eq: "projects/service/keycrime/process/20.png" }
    ) {
      ...fluidKCImage
    }
    kcprocess21: file(
      relativePath: { eq: "projects/service/keycrime/process/21.png" }
    ) {
      ...fluidKCImage
    }
    kcprocess22: file(
      relativePath: { eq: "projects/service/keycrime/process/22.png" }
    ) {
      ...fluidKCImage
    }
    kcprocess23: file(
      relativePath: { eq: "projects/service/keycrime/process/23.jpg" }
    ) {
      ...fluidKCImage
    }
    kcprocess24: file(
      relativePath: { eq: "projects/service/keycrime/process/24.jpg" }
    ) {
      ...fluidKCImage
    }
    kcoutcome25: file(
      relativePath: { eq: "projects/service/keycrime/outcome/25.png" }
    ) {
      ...fluidKCImage
    }
    kcoutcome27: file(
      relativePath: { eq: "projects/service/keycrime/outcome/27.jpg" }
    ) {
      ...fluidKCImage
    }
    kc_process: file(
      relativePath: { eq: "projects/process/keycrime-process.png" }
    ) {
      ...fluidKCImage
    }
    project1: file(relativePath: { eq: "projects/10.png" }) {
      ...fluidKCImage
    }
    project2: file(relativePath: { eq: "projects/19.png" }) {
      ...fluidKCImage
    }
  }
`
